import { GetByRestaurantId } from '@core/api/Branch'

import router from '@/router'
import { ref, watch } from '@vue/composition-api'

export default function useBranchesList() {
  const tableColumns = [
    { text: 'id', value: 'Id' },
    { text: 'name', value: 'Name', width: 200 },
    { text: 'Open', value: 'OpenTime' },
    { text: 'Close', value: 'CloseTime' },
    { text: 'STATUS', value: 'Status', sortable: false },
    { text: 'Address', value: 'Address', sortable: false },
  ]

  const branchListTable = ref([])

  const searchQuery = ref('')
  const resid = ref(router.currentRoute.params.id)
  const statusFilter = ref(null)
  const totalbranchListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const branchTotalLocal = ref([])

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const fetchBranches = () => {
    GetByRestaurantId({ id: resid.value, any: searchQuery.value, status: statusFilter.value })
      .then(response => {
        // handle success

        branchListTable.value = response

        totalbranchListTable.value = response.lenght
        branchTotalLocal.value = response.lenght

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, statusFilter, options], () => {
    loading.value = true
    fetchBranches()
  })

  return {
    branchListTable,
    tableColumns,
    searchQuery,
    resid,
    statusFilter,
    totalbranchListTable,
    loading,
    options,
    branchTotalLocal,
    fetchBranches,
  }
}
