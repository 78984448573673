<template>
  <div>
    <v-card>
      <!-- search -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-spacer></v-spacer>

        <div class="d-flex align-center pb-5">
          <v-text-field
            v-model="searchQuery"
            single-line
            dense
            outlined
            hide-details
            placeholder="Search"
            class="Orders-List-search me-3"
          ></v-text-field>

          <!-- status filter -->
          <v-col cols="12" sm="4">
            <v-select
              v-model="statusFilter"
              label="Status"
              :items="$store.state.status['branches']"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </v-col>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="branchListTable"
        :options.sync="options"
        :server-items-length="totalbranchListTable"
        :loading="loading"
      >
        <!-- id -->
        <template #[`item.Id`]="{ item }" style="align: 'start'">
          <router-link class="text-start sortable" :to="{ name: 'apps-item-preview', params: { id: item.Id } }">
            #{{ item.Id }}
          </router-link>
        </template>

        <!-- Name -->
        <template #[`item.Name`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Name }}</span>
              <span class="text-xs">{{ item.Description }}</span>
            </div>
          </div>
        </template>
        <!-- status -->
        <template #[`item.Status`]="{ item }">
          <v-chip
            small
            :color="
              $store.state.status['branches'].find(obj => {
                return obj.value == item.Status
              }).variant || 'primary'
            "
            :class="`${
              $store.state.status['branches'].find(obj => {
                return obj.value == item.Status
              }).variant || 'primary'
            }--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.Status }}
          </v-chip>
        </template>
        <template #[`item.Address`]="{ item }">
          <v-btn class="text-start sortable" :to="{ name: 'AddressList', params: { id: item.Id } }"> Address </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPlus, mdiTrendingUp } from '@mdi/js'
import useBranchList from './useBranchList'

// import store from '@/store'
// rest name, id, customer name, date & time, emp name, complain
export default {
  setup() {
    const {
      branchListTable,
      tableColumns,
      searchQuery,
      totalbranchListTable,
      loading,
      options,
      branchTotalLocal,
      fetchBranches,
      statusFilter,
      resid,
    } = useBranchList()

    return {
      resid,
      branchListTable,
      tableColumns,
      searchQuery,
      totalbranchListTable,
      loading,
      options,
      branchTotalLocal,
      fetchBranches,
      statusFilter,
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#Orders-List {
  .Orders-List-actions {
    max-width: 7.81rem;
  }
  .Orders-List-search {
    max-width: 10.625rem;
  }
  .Orders-List-status {
    max-width: 11.3rem;
  }
}
</style>
