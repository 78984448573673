import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VSpacer),_c('div',{staticClass:"d-flex align-center pb-5"},[_c(VTextField,{staticClass:"Orders-List-search me-3",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Search"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VSelect,{attrs:{"label":"Status","items":_vm.$store.state.status['branches'],"item-text":"title","item-value":"value","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.branchListTable,"options":_vm.options,"server-items-length":_vm.totalbranchListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-start sortable",attrs:{"to":{ name: 'apps-item-preview', params: { id: item.Id } }}},[_vm._v(" #"+_vm._s(item.Id)+" ")])]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.Name))]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(item.Description))])])])]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.$store.state.status['branches'].find(function (obj) {
              return obj.value == item.Status
            }).variant || 'primary') + "--text"),attrs:{"small":"","color":_vm.$store.state.status['branches'].find(function (obj) {
              return obj.value == item.Status
            }).variant || 'primary'}},[_vm._v(" "+_vm._s(item.Status)+" ")])]}},{key:"item.Address",fn:function(ref){
            var item = ref.item;
return [_c(VBtn,{staticClass:"text-start sortable",attrs:{"to":{ name: 'AddressList', params: { id: item.Id } }}},[_vm._v(" Address ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }